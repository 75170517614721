function Once(fn, context) {
    var result;

    return function () {
        if (fn) {
            result = fn.apply(context || this, arguments);
            fn = null;
        }

        return result;
    };
}

function Cookie() {
    var self = this;
    self.document = document;

    self.setExpiration = function (expires) {
        return new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24);
    };

    self.setCookie = function (cookie) {
        self.document.cookie = cookie;
        return true;
    };

    self.createCookie = function (name, value, expires, path, domain) {
        var cookie = name + '=' + escape(value) + ';';

        if (expires) {
            // If it's a date
            if (expires instanceof Date) {
                // If it isn't a valid date
                if (isNaN(expires.getTime())) {
                    expires = new Date();
                }
            } else {
                expires = self.setExpiration(expires);
            }
            cookie += `expires=${expires.toUTCString()};`;
        }

        if (path) {
            cookie += `path=${path};`;
        }

        if (domain) {
            cookie += `domain=${domain};`;
        }

        self.setCookie(cookie);
    };

    self.getCookie = function (name) {
        const nameEquals = name + '=';
        const ca = self.document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEquals) === 0) {
                return c.substring(nameEquals.length, c.length);
            }
        }
        return null;
    };

    self.deleteCookie = function (name, path, domain) {
        // If the cookie exists
        if (self.getCookie(name)) {
            self.createCookie(name, '', -1, path, domain);
        }
    };
}

function LanguageSelection() {
    var self = this;
    var googleLangIconInterval;

    self.langContainer = $('#langPopover');
    self.langIcon = $('#langPopover span');
    self.googleFrame = null;
    self.googleTrigger = '';

    self.setLanguage = function () {
        self.initLang = $('.goog-te-menu-value span').html();

        self.langClass = '';
        if (self.initLang === 'Chinese (Simplified)') {
            self.langClass = $('#languagePopover #Chinese-Simplified').children('span').attr('class');
        } else if (self.initLang === 'Chinese (Traditional)') {
            self.langClass = $('#languagePopover #Chinese-Traditional').children('span').attr('class');
        } else if (self.initLang === 'Myanmar (Burmese)') {
            self.langClass = $('#languagePopover #Myanmar-Burmese').children('span').attr('class');
        } else if (self.initLang === 'Haitian Creole') {
            self.langClass = $('#languagePopover #Haitian-Creole').children('span').attr('class');
        } else if (self.initLang && self.initLang !== 'Select Language') {
            self.langClass = $(`#languagePopover #${self.initLang}`).children('span').attr('class');
        }

        if (self.initLang !== 'Select Language') {
            self.langIcon.attr('class', `img-circle ${self.langClass}`);
        }

        self.langContainer.css({ opacity: 0.0, visibility: 'visible' }).animate({ opacity: 1.0 });
    };

    self.langIconAnimateIn = function () {
        self.langIcon.attr('class', `img-circle ${self.newLangClass}`).animate({ opacity: 1.0 }, 1000);
    };

    self.changeLanguage = function (e) {
        e.preventDefault();
        const lang = $(this).data('lang');
        self.newLangClass = $(this).children('span').attr('class');
        self.googleFrame = $('.goog-te-menu-frame:first');
        self.googleTrigger = self.googleFrame.contents().find(`.goog-te-menu2-item span.text:contains(${lang})`);

        if (!self.googleFrame.length) {
            alert('Error: Could not find Google translate frame.');
            return false;
        }

        self.langIcon.animate({ opacity: 0.0 }, 500, self.langIconAnimateIn);

        self.googleTrigger.click();

        self.langContainer.popover('hide');
        return false;
    };

    self.setGoogleLangIcon = function () {
        if ($('.goog-te-menu-value span').html()) {
            window.setTimeout(self.setLanguage, 1000);
            clearInterval(googleLangIconInterval);
        }
    };

    self.init = function () {
        $(document).on('click', '#languagePopover ul li a', self.changeLanguage);

        googleLangIconInterval = setInterval(self.setGoogleLangIcon, 1000);
    };
}

function HeaderModel() {
    var self = this;

    self.fullHeader = $('#mainHeader');
    self.menuHeader = $('#mainMenu');
    self.pageWrap = $('#wrapper');
    self.footer = $('#footer');
    self.triggerClose = $('#body, #footer');

    self.currentPos = 0;
    self.lastPos = 0;
    self.scrollMinimum = 50;

    self.state = {
        mobileMenuOpen: false
    };

    self.setHeader = function () {
        // If they scrolled down and are past the navbar, add class .posScrollUp.
        // This is necessary so you never see what is "behind" the navbar.
        if (self.currentPos > self.lastPos && self.currentPos > self.navbarHeight) {
            // Scroll Down
            self.fullHeader.removeClass('posScrollUp');
            self.fullHeader.addClass('posScrollDown');
            self.fullHeader.css('top', -(self.navbarHeight - self.triggerHeight));
        } else if (self.currentPos < self.lastPos && self.currentPos > self.navbarHeight) {
            // Scroll Up
            self.fullHeader.removeClass('posScrollDown');
            self.fullHeader.addClass('posScrollUp');
            self.fullHeader.css('top', 0);
        }

        self.lastPos = self.currentPos;
    };

    self.resetHeader = function () {
        self.fullHeader.removeClass('posScrollUp');
        self.fullHeader.removeClass('posScrollDown');
        self.fullHeader.css('top', 0);
    };

    self.hasScrolled = function () {
        self.currentPos = $(window).scrollTop();
        self.navbarHeight = self.fullHeader.outerHeight();
        self.triggerHeight = self.menuHeader.outerHeight();
        self.underScrolled = Math.abs(self.lastPos - self.currentPos) <= self.scrollMinimum;

        if (self.currentPos === 0) {
            self.resetHeader();
        } else if (self.underScrolled) {
            return;
        } else {
            self.setHeader();
        }
    };

    self.setupScroll = function () {
        $(window).scroll(self.hasScrolled);
    };

    self.closeMobileMenu = function () {
        $('body').css('overflow', '');
        $('#body .overlay').remove();
        self.pageWrap.removeClass('open');
        self.footer.removeClass('open');
        self.state.mobileMenuOpen = false;
        self.triggerClose.off('click');
    };

    self.setupMobileMenu = function (e) {
        e.preventDefault();

        if (self.state.mobileMenuOpen) {
            $('body').css('overflow', '');
            $('#body .overlay').remove();
            self.pageWrap.removeClass('open');
            self.footer.removeClass('open');
            self.state.mobileMenuOpen = false;
        } else {
            self.pageWrap.addClass('open');
            self.footer.addClass('open');
            $('body').css('overflow', 'hidden');
            $('#body').prepend("<div class='overlay'></div>");
            self.state.mobileMenuOpen = true;

            self.triggerClose.on('click', self.closeMobileMenu);
        }
    };
}

function AllPages() {
    var self = this;
    self.header = new HeaderModel();
    self.language = new LanguageSelection();
    self.iOSDevice = false;

    var mobileMenuTrigger = $('#trigger');

    self.setTableScrollbarWidth = function (ele) {
        const scrollbar = $(ele).find('.content');
        const tableWidth = $(ele).find('.table').width();
        scrollbar.css('width', tableWidth);
    }
    self.setTopScrollBarListener = function (ele) {
        const topScrollbar = $(ele).children('.topScroll');
        topScrollbar.scroll(function () {
            $(ele).find('.table-responsive').scrollLeft(topScrollbar.scrollLeft());
        });
    }
    self.setBottomScrollBarListener = function (ele) {
        var scrollLeftPrev = 0;
        const responsiveTable = $(ele).find('.table-responsive');
        responsiveTable.scroll(function () {
            const yScroll = responsiveTable.scrollLeft(),
                viewWidth = responsiveTable.outerWidth(),
                scrollWidth = responsiveTable.get(0).scrollWidth;

            if (yScroll >= scrollWidth - viewWidth) {
                $(ele).removeClass('shadow-right').addClass('shadow-left');
            } else if (yScroll === 0) {
                $(ele).removeClass('shadow-left').addClass('shadow-right');
            } else {
                $(ele).addClass('shadow-left').addClass('shadow-right');
            }

            scrollLeftPrev = yScroll;

            if (!self.isIOSBrowser()) {
                $(ele).children('.topScroll').scrollLeft(responsiveTable.scrollLeft());
            };

        });
    }

    self.setResponsiveTableScrollbars = function (i, ele) {

        $(ele).addClass('shadow-right');

        if (!self.isIOSBrowser()) {
            $(ele).prepend('<div class="topScroll"><div class="content"></div></div>');
        };


        const element = $(ele);
        const table = element.find('.table');

        const tableLoaded = setInterval(() => {
            if (table.length > 0 && !table.is(':empty') ) {
                if (!self.isIOSBrowser()) {
                    self.setTableScrollbarWidth(element);
                    self.setTopScrollBarListener(element);
                };
                self.setBottomScrollBarListener(element);
                clearInterval(tableLoaded);
            }
        }, 1000);
    }

    self.isIOSBrowser = function() {
        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/(iPod|iPhone|iPad)/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i) || !!ua.match(/AppleWebKit/i);
        self.iOSDevice = iOS && webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i);

        return self.iOSDevice;
    }

    self.setResponsiveTables = function () {
        var tableWrapper = $('.responsiveTableWrapper');

        $(window).on('resize', () => {
            if ($(window).width() < 768) {
                tableWrapper.each(function (i, ele) {
                    const element = $(ele);
                    self.setTableScrollbarWidth(element);
                });
            }
        });


        if (tableWrapper.length) {
            tableWrapper.each(self.setResponsiveTableScrollbars);
        }
    }

    self.setupHeaderAndScroll = function () {
        self.header.setupScroll();
    };

    self.initializeBindings = function () {
        self.setupHeaderAndScroll();
        $(window).on('resize', self.windowResizeChecks);
        mobileMenuTrigger.on('click', self.header.setupMobileMenu);

        // Default select2 inputs to allow the selection to be cleared.
        $.fn.select2.defaults.set("allowClear", true);
        $.fn.select2.defaults.set("placeholder", "Select...");

        /*
        * Hacky fix for a bug in select2 with jQuery 3.6.0's new nested-focus "protection"
        * see: https://github.com/select2/select2/issues/5993
        * see: https://github.com/jquery/jquery/issues/4382
        *
        * TODO: Recheck with the select2 GH issue and remove once this is fixed on their side
        */
        $(document).on('select2:open', () => {
            let allFound = document.querySelectorAll('.select2-container--open .select2-search__field');
            allFound[allFound.length - 1].focus();
        });

        // prevent duplicate submissions
        $('form').submit(self.disableSubmit);
    };

    self.windowResizeChecks = function () {
        if ($(window).width() > 991) {
            $('body').css('overflow', '');
            $('#body .overlay').remove();
            $('#wrapper').removeClass('open');
            $('#footer').removeClass('open');
        }
        if ($(window).width() < 768) {
            const menuHeight = $('#mainHeader').innerHeight();
            $('#mainHeaderWrapper').css('height', menuHeight);
        } else {
            $('#mainHeaderWrapper').css('height', '');
        }
        $('#userDetailsPopover, #langPopover, #localize-langPopover').popover('hide');
    };

    self.disableSubmit = function () {
        if ($('form').valid()) {
            $('input[type=submit], button[type=submit]', this).attr('disabled', 'disabled');
        }
    };

    self.bindPopoversClick = function (e) {
        //did not click a popover toggle or popover
        if ($(e.target).parents('.popover.in').length === 0) {
            $('#userDetailsPopover, #langPopover, #localize-langPopover').popover('hide');
        }
    };

    self.setupPopoverClick = function () {
        $('body').on('click', self.bindPopoversClick);
    };

    self.bindPopovers = function () {
        self.showPopover = function () {
            $(this).popover('show');
        };

        $('#userDetailsPopover, #langPopover, #localize-langPopover')
            .click(self.showPopover)
            .on('hidden.bs.popover',
                function () {
                    $('body').off('click');
                })
            .on('shown.bs.popover',
                function () {
                    self.setupPopoverClick();
                });
    };

    var generatePopoverOptions = function (contentSelector, minWidth, maxWidth) {
        const styleAttr = `style="min-width: ${minWidth || 100}px !important; max-width: ${maxWidth || 800}px !important;"`;

        return {
            html: true,
            animation: true,
            trigger: 'manual',
            placement: 'auto bottom',
            container: '.mainHeader',
            content: function () {
                return $(contentSelector).html();
            },
            template: `<div class="popover" role="tooltip" ${styleAttr
                }><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"><div class="data-content"></div></div></div>`
        };
    };

    self.setupPopovers = function () {
        self.localizeJSPopoverOptions = generatePopoverOptions('#custom-localize-widget', null, null);
        self.languagePopoverOptions = generatePopoverOptions('#languageSelection', null, null);
        self.avatarMenuPopoverOptions = generatePopoverOptions('#userPopoverWrapper', 300, 400);

        $('#localize-langPopover').popover(self.localizeJSPopoverOptions);
        $('#langPopover').popover(self.languagePopoverOptions);
        $('#userDetailsPopover').popover(self.avatarMenuPopoverOptions);

        self.bindPopovers();
    };
    self.languages = [
        //["LanguageID", "LanguageTitle", "LanguageIcon"],
        ['Afrikaans', 'Afrikaans', 'za'],
        ['Albanian', 'Albanian', 'al'],
        ['Arabic', 'Arabic', 'sa'],
        ['Armenian', 'Armenian', 'am'],
        ['Azerbaijani', 'Azerbaijani', 'az'],
        ['Basque', 'Basque', 'bu'],
        ['Belarusian', 'Belarusian', 'by'],
        ['Bengali', 'Bengali', 'bd'],
        ['Bosnian', 'Bosnian', 'ba'],
        ['Bulgarian', 'Bulgarian', 'bg'],
        ['Catalan', 'Catalan', 'ct'],
        ['Cebuano', 'Cebuano', 'ph'],
        ['Chichewa', 'Chichewa', 'mw'],
        ['Chinese-Simplified', 'Chinese (Simplified)', 'cn'],
        ['Chinese-Traditional', 'Chinese (Traditional)', 'cn'],
        ['Croatian', 'Croatian', 'hr'],
        ['Czech', 'Czech', 'cz'],
        ['Danish', 'Danish', 'dk'],
        ['Esperanto', 'Esperanto', 'ep'],
        ['Estonian', 'Estonian', 'ee'],
        ['Filipino', 'Filipino', 'ph'],
        ['Finnish', 'Finnish', 'fi'],
        ['French', 'French', 'fr'],
        ['Galician', 'Galician', 'gc'],
        ['Georgian', 'Georgian', 'go'],
        ['German', 'German', 'de'],
        ['Greek', 'Greek', 'gr'],
        ['Gujarati', 'Gujarati', 'in'],
        ['Haitian-Creole', 'Haitian Creole', 'ha'],
        ['Hausa', 'Hausa', 'hs'],
        ['Hebrew', 'Hebrew', 'il'],
        ['Hindi', 'Hindi', 'in'],
        ['Hmong', 'Hmong', 'ho'],
        ['Hungarian', 'Hungarian', 'hu'],
        ['Icelandic', 'Icelandic', 'is'],
        ['Igbo', 'Igbo', 'ig'],
        ['Indonesian', 'Indonesian', 'id'],
        ['Irish', 'Irish', 'ie'],
        ['Italian', 'Italian', 'it'],
        ['Japanese', 'Japanese', 'jp'],
        ['Javanese', 'Javanese', 'jv'],
        ['Kannada', 'Kannada', 'ka'],
        ['Kazakh', 'Kazakh', 'kz'],
        ['Khmer', 'Khmer', 'kh'],
        ['Korean', 'Korean', 'kr'],
        ['Lao', 'Lao', 'la'],
        ['Latin', 'Latin', 'ln'],
        ['Latvian', 'Latvian', 'lv'],
        ['Lithuanian', 'Lithuanian', 'lt'],
        ['Macedonian', 'Macedonian', 'mk'],
        ['Malagasy', 'Malagasy', 'mg'],
        ['Malay', 'Malay', 'my'],
        ['Malayalam', 'Malayalam', 'mb'],
        ['Maltese', 'Maltese', 'mt'],
        ['Maori', 'Maori', 'mi'],
        ['Marathi', 'Marathi', 'mj'],
        ['Mongolian', 'Mongolian', 'mn'],
        ['Myanmar-Burmese', 'Myanmar (Burmese)', 'mm'],
        ['Nepali', 'Nepali', 'np'],
        ['Norwegian', 'Norwegian', 'no'],
        ['Persian', 'Persian', 'ir'],
        ['Polish', 'Polish', 'pl'],
        ['Portuguese', 'Portuguese', 'pt'],
        ['Punjabi', 'Punjabi', 'pj'],
        ['Romanian', 'Romanian', 'ro'],
        ['Russian', 'Russian', 'ru'],
        ['Serbian', 'Serbian', 'rs'],
        ['Sesotho', 'Sesotho', 'ls'],
        ['Sinhala', 'Sinhala', 'lk'],
        ['Slovak', 'Slovak', 'sk'],
        ['Slovenian', 'Slovenian', 'si'],
        ['Somali', 'Somali', 'so'],
        ['Spanish', 'Spanish', 'es'],
        ['Sundanese', 'Sundanese', 'su'],
        ['Swahili', 'Swahili', 'ke'],
        ['Swedish', 'Swedish', 'se'],
        ['Tajik', 'Tajik', 'tj'],
        ['Tamil', 'Tamil', 'ta'],
        ['Telugu', 'Telugu', 'in'],
        ['Thai', 'Thai', 'th'],
        ['Turkish', 'Turkish', 'tr'],
        ['Ukrainian', 'Ukrainian', 'ua'],
        ['Urdu', 'Urdu', 'pk'],
        ['Uzbek', 'Uzbek', 'uz'],
        ['Vietnamese', 'Vietnamese', 'vn'],
        ['Welsh', 'Welsh', 'we'],
        ['Yiddish', 'Yiddish', 'yi'],
        ['Yoruba', 'Yoruba', 'yo'],
        ['Zulu', 'Zulu', 'za'],
        ['English', 'English', 'us']
    ];

    self.setupLanguages = function () {
        $('#languagePopover').append("<h4>Select A Language:</h4><ul class='languages'></ul>");

        for (let i = 0; i < self.languages.length; i++) {
            const langId = self.languages[i][0];
            const langTitle = self.languages[i][1];
            const langIcon = self.languages[i][2];
            $('#languagePopover ul.languages')
                .append(
                    `<li><a href='#' id='${langId}' alt='${langTitle}' data-lang='${langTitle}'><span class='flag-icon flag-icon-squared flag-icon-${langIcon
                    }'></span> ${langTitle}</li>`);
        }
    };

    self.init = function () {
        self.setResponsiveTables();
        self.initializeBindings();
        self.setupPopovers();
        self.setupLanguages();
        self.language.init();
        self.windowResizeChecks();
    };
}

$(function () {
    window.allPageModel = new AllPages();
    window.allPageModel.init();
});

(function ($) {
    $.fn.addDateIcon = function () {
        this.each(function () {
            var self = $(this);
            self.after('<span class="dateTimeIcon fa fa-calendar"></span>');
            self.next('.dateTimeIcon')
                .click(function () {
                    self.focus();
                });
        });
        return this;
    };
})(jQuery);

$(function () {
    $(document).on('click', '#exitLink', function () {
        history.go(-1);
    });
});